import React, { createRef, useEffect } from "react"
import Layout from "../shared/layout/Layout"

import lottie from "lottie-web"
import animation from "../assets/animations/lf30_editor_b1g7ghr6.json"
import styled from "@emotion/styled"

const NotFound = _ => {
  let animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy()
  }, [])

  return (
    <Layout>
      <div style={{ minHeight: "71.5vw" }}>
        <Animation className={"animation-container"} ref={animationContainer} />
      </div>
    </Layout>
  )
}

const Animation = styled.div`
  padding-top: 5%;
  margin: auto;
  width: 85%;
`

NotFound.propTypes = {}

export default NotFound
